import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Disqus from '../Disqus/Disqus'
import './style.scss'
// mport Adsense from '../GoogleAdsense'
import Sidebar from '../Sidebar'
//import Author from '../Author'

import Helmet from 'react-helmet'

import rehypeReact from 'rehype-react'
import AdsenseBeforeToC from '../GoogleAdsenseBeforeToC';
import AdsenseAfterArticle from '../GoogleAdsenseAfterArticle'
import AdsenseInArticle from '../GoogleAdsenseInArticle'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'adsensebeforetoc': AdsenseBeforeToC,
    'adsenseinarticle': AdsenseInArticle,
    'adsenseafterarticle': AdsenseAfterArticle,
  },
}).Compiler;

class PostTemplateDetails extends React.Component {
  render() {
    const { author } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const htmlAst = post.htmlAst
    const featuredImgSize = post.frontmatter.featuredImage.childImageSharp.sizes.src
    const { tags, category } = post.frontmatter;
    const { tagSlugs, categorySlug } = post.fields

    const path = post.frontmatter;
    const postpath = `https://kinnikumegane.com/${path}`
    const { description: postDescription } = post.frontmatter
    const image = `https://kinnikumegane.com/${featuredImgSize}`

    // following const is added to activate Prev and Next button
    const { previous, next } = this.props.pageContext
    
    {/* 
    const homeBlock = (
      <div>
        <Link className="post-single__home-button" to="/">
          Back
        </Link>
      </div>
    )
    */}
    
    const tagsBlock = (
      <div className="post-single__tags">
        <ul className="post-single__tags-list">
          {tagSlugs &&
            tagSlugs.map((tag, i) => (
              <li className="post-single__tags-list-item" key={tag}>
                <Link to={tag} className="post-single__tags-list-item-link">
                  {post.frontmatter.tags[i]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
      // <div className="post-single__tags">
      //   <ul className="post-single__tags-list">
      //     {tags &&
      //       tags.map((tag, i) => (
      //         <li className="post-single__tags-list-item" key={tag}>
      //           <Link to={tag} className="post-single__tags-list-item-link">
      //             {post.frontmatter.tags[i]}
      //           </Link>
      //         </li>
      //       ))}
      //   </ul>
      // </div>
    )

    const commentsBlock = (
      <div>
        <Disqus
          postNode={post}
          siteMetadata={this.props.data.site.siteMetadata}
        />
      </div>
    )

    return (      
      <div class="contentmain">
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={post.frontmatter.title} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:description" content={postDescription} />
          <meta name="twitter:site" content="@KinnikuMeganeDe" />
          
          <meta property="og:title" content={post.frontmatter.title} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={image} />
          <meta property="og:url" content={postpath} />
          <meta property="og:description" content={postDescription} />
        </Helmet>
        
        <div className="post-single">
          {/* {homeBlock} */}
          <div className="post-single__inner">
            <div className="post-single__pankuzu">
              <span className="post-single__pankuzu_home-in-pankuzu"><Link to="/">ホーム</Link></span>
              <span className="post-single__pankuzu_firsticon"></span>
              <span className="post-single__pankuzu_category-in-pankuzu"><Link className="" to={categorySlug}>{category}</Link></span>
              <span className="post-single__pankuzu_firsticon"></span>
              <span className="post-single__pankuzu_title">{post.frontmatter.title}</span>
            </div>
            
            <div className="post-single__date_category_tag">
              <div className="post-single__date_category_tag__date">
                {moment(post.frontmatter.date).format('YYYY年M月DD日')}
              </div>
              {/*}
              <div className="post-single__date_category_tag__meta">
                <span className="post-single__date_category_tag__meta-divider" />
                <span className="post-single__date_category_tag__meta-category" key={categorySlug}>
                  <Link to={categorySlug} className="post-single__date_category_tag__meta-category-link">
                    {category}
                  </Link>
                </span>
              </div>
              */}
            </div>
            <h1 className="post-single__title">{post.frontmatter.title}</h1>
            {/*}
            <span className="post-single__date_category_tag__meta-tag" key={tagSlugs}>
              <ul className="post-single__date_category_tag__meta-ul">
              {tagSlugs &&
                tagSlugs.map((tag, i) => (
                  <li className="post-single__date_category_tag__meta-tag-list-item" key={tag}>
                    <Link to={tag} className="post-single__date_category_tag__meta-tag-list-item-link">
                      {tags[i]}
                    </Link>
                    <span className="post-single__date_category_tag__meta-tag-divider" />
                  </li>
                ))}
              </ul>
            </span>
                */}
            

            <div className="post-single__body">{renderAst(htmlAst)}</div>
            {/*   eslint-disable-next-line react/no-danger
             dangerouslySetInnerHTML={{ __html: post.html }} */}
            
            {/* kinniku commented out
            <div className="post-single__date">
              <em>
                Published {moment(post.frontmatter.date).format('MMM D YYYY')}
              </em>
            </div>
            */}
          </div>
          <div className="post-single__footer">
            <h3 className="post-single__footer-sharesuru">シェアする</h3>
            {/* add SNS button*/}
            <a className="twitter-share-button" data-size="large" href="https://twitter.com/share?ref_src=twsrc%5Etfw" data-show-count="false">Tweet</a>

            {/* deactivate for the time being
            <FacebookShareButton className="post-single__footer-sns-facebook" quote={post.frontmatter.title + "\n"} url={url}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            {/* below is for Hatebu *
            <Helmet>
              <script type="text/javascript" src="//b.st-hatena.com/js/bookmark_button.js" charset="utf-8" async="async" />
            </Helmet>
            <a
              href="http://b.hatena.ne.jp/entry/"
              className="hatena-bookmark-button"
              data-hatena-bookmark-layout="touch"
              data-hatena-bookmark-lang="ja"
              title="このエントリーをはてなブックマークに追加"
              width="40" height="40"
            >
            <img
                width="40"
                height="40"
                src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                alt="このエントリーをはてなブックマークに追加"
                style={{border: 'none'}}
                />
            </a>
            */}
            
            {/*{tagsBlock} */}
            
            {/* kinniku added Author section here */}
            {/* <Adsense /> */}

            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
                marginTop: 60,
              }}
            >
              <li className={next ? '' : 'disabled'}>
                {next&& (
                  <Link to={next.fields.slug} rel="next">
                    ←　新しい記事　{/* {next.frontmatter.title} */}
                  </Link>
                )}
              </li>

              <li className={previous ? '' : 'disabled'}>
              {/*
                <Link to={`post${previous.fields.slug}`} rel="prev" className={previous ? '' : 'disabled'}>
                  ← {previous.frontmatter.title}
                </Link>
              */}
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    {/* {previous.frontmatter.title}　*/} 古い記事　→
                  </Link>
                )}
              </li>
                
              
            </ul>

            {/*
            <hr />
            <Author {...this.props} />
            */}
            <p className="post-single__footer-text">
              {/* {subtitle} */}
              <a
                href={`https://twitter.com/${author.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <br /> <strong>{author.name}</strong> on Twitter */}
              </a>
            </p>
            {commentsBlock}
          </div>
        </div>
        <Sidebar {...this.props} />
      </div>
    )
  }
}

export default PostTemplateDetails