import React from 'react';
import AdSense from 'react-adsense';
import './style.scss'

const AdsenseInArticle = () => (
    <ins>
        <AdSense.Google
            client='ca-pub-7870827999751510'
            slot='5301607264'
            style={{ display:'block' }}
            // auto fill with responsive ads
            format ='auto'
            responsive='true'
            layoutKey='-gw-1+2a-9x+5c'
        />
    </ins>
);

export default AdsenseInArticle;